import React from 'react';
import { Helmet } from 'react-helmet';
import Lottie from 'lottie-react';

import { useMediaQuery } from '@material-ui/core';
import { GridBox, GridContainer, Iframe } from './styledComponents';
// import { Stepper } from '../../components/Stepper';
import { useMaritalStatuses } from './hooks/useMaritalStatus';
import lottie from '../../assets/register.json';
import { Title } from '../../components/Stepper/style';
import { media } from '../../helper';

export default function BeADistributor() {
  const { isLoading } = useMaritalStatuses();

  const isMobile = useMediaQuery(media.medium);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <GridContainer>
      <Helmet>
        <title>Conviértete en distribuidora</title>
      </Helmet>
      {!isMobile && (
        <GridBox background="#E9FFF0">
          <div className="centered-box">
            <Title>Únete a nuestro equipo de distribuidoras</Title>
            <Lottie
              animationData={lottie}
              loop
              style={{ width: 650, height: 650 }}
            />
          </div>
        </GridBox>
      )}
      {/* <GridBox>
        <Stepper maritalStatusOptions={maritalStatuses} />
      </GridBox> */}
      <div className="form">
        <Iframe src="https://share.hsforms.com/1eDL4tkINTTmL2WnBFeaWigs8rzq" />
      </div>
    </GridContainer>
  );
}
