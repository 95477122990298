import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import NotFoundPage from './containers/NotFoundPage';
import MainLayout from './containers/MainLayout';
import HomePage from './containers/HomePage';
import WhoWeAre from './containers/WhoWeAre';
import BeADistributor from './containers/BeADistributor';
import DistributorsMobile from './containers/DistributorsMobile';
import Offices from './containers/Offices';
import Investors from './containers/Investors';
import CanjeVale from './containers/CanjeVale';
import CanjeValeVentanilla from './containers/CanjeValeVentanilla';
import AvisoPrivacidad from './containers/AvisoPrivacidad';
import AppRoute from './components/AppRoute';
import ScrollToTop from './components/ScrollToTop';
import EditarPerfil from './containers/EdicionCliente';
import CanjeValeDigital from './containers/CanjeValeDigital';
import CanjeVentanilla from './containers/CanjeVentanilla';
import Dinamica from './containers/Dinamica';
import CaptacionNuevasCuentas from './containers/CaptacionNuevasCuentas';
import CanjeValeIncode from './containers/CanjeValeIncode';
import { GlobalActionsProvider } from './context/globalActions';
import { Snackbar } from './components/Snackbar';
import BeADistributorLayout from './components/BeADistributorLayout';
import RejectedPage from './containers/Rejected';
import Success from './containers/Success';

const theme = createTheme({
  typography: {
    fontFamily: ['Avenir', 'Helvetica Neue', 'Arial'].join(','),
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#00843D',
    },
    secondary: {
      main: '#E3F1DF',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
    info: {
      main: '#2196f3',
    },
    warning: {
      main: '#ff9800',
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f8f8f8',
        },
      },
    },
  },
  button: {
    primary: {
      borderRadius: '32px',
      backgroundColor: '#00AE42',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00BC70',
        color: '#ffffff',
      },
    },
  },
});

function App() {
  return (
    <GlobalActionsProvider>
      <MuiThemeProvider theme={theme}>
        <div>
          <Helmet titleTemplate="PrestaVale - %s" defaultTitle="PrestaVale">
            <meta
              name="description"
              content="PrestaVale - tu vale en un click"
            />
          </Helmet>
          <Router>
            <>
              <ScrollToTop />
              <Switch>
                <AppRoute
                  exact
                  path="/"
                  layout={MainLayout}
                  component={HomePage}
                />
                <AppRoute
                  exact
                  path="/nosotros"
                  layout={MainLayout}
                  component={WhoWeAre}
                />
                <AppRoute
                  exact
                  path="/conviertete-en-distribuidora"
                  layout={BeADistributorLayout}
                  component={BeADistributor}
                />
                <AppRoute
                  exact
                  path="/distribuidoras-movil"
                  layout={MainLayout}
                  component={DistributorsMobile}
                />
                <AppRoute
                  exact
                  path="/sucursales"
                  layout={MainLayout}
                  component={Offices}
                />
                <AppRoute
                  exact
                  path="/inversionistas"
                  layout={MainLayout}
                  component={Investors}
                />
                <AppRoute
                  exact
                  path="/aviso-privacidad"
                  layout={MainLayout}
                  component={AvisoPrivacidad}
                />
                <AppRoute
                  exact
                  path="/captacion-nuevas-cuentas"
                  layout={MainLayout}
                  component={CaptacionNuevasCuentas}
                />
                <AppRoute
                  exact
                  path="/rechazado"
                  layout={MainLayout}
                  component={RejectedPage}
                />

                <AppRoute
                  exact
                  path="/aceptado"
                  layout={MainLayout}
                  component={Success}
                />
                <Route
                  exact
                  path="/canje-vale/:token"
                  component={CanjeValeDigital}
                />
                <Route
                  exact
                  path="/canje-vale-incode/:token"
                  component={CanjeValeIncode}
                />
                <Route
                  exact
                  path="/canje-vale-por-ventanilla/:token"
                  component={CanjeVentanilla}
                />
                <Route
                  exact
                  path="/editar-datos-cliente/:token"
                  component={EditarPerfil}
                />
                <Route
                  exact
                  path="/canje-vale-digital/:token"
                  component={CanjeVale}
                />
                <Route
                  exact
                  path="/canje-ventanilla/:token"
                  component={CanjeValeVentanilla}
                />

                <AppRoute
                  exact
                  path="/dinamica"
                  layout={MainLayout}
                  component={Dinamica}
                />

                <Route component={NotFoundPage} />
              </Switch>
            </>
          </Router>
          <Snackbar />
        </div>
      </MuiThemeProvider>
    </GlobalActionsProvider>
  );
}

export default App;
