import { useEffect, useState } from 'react';
import { authFetcher } from '../../../components/Stepper/api/authFetcher';

export const useMaritalStatuses = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [maritalStatuses, setMaritalStatuses] = useState([]);

  const loadData = async () => {
    setIsLoading(true);

    const loadMaritalStatusData = async () => {
      try {
        const { data } = await authFetcher.get('/onboarding/marital-statuses');
        setMaritalStatuses(data.data);
      } catch (error) {
        console.error('Error loading marital statuses:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadMaritalStatusData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return {
    isLoading,
    maritalStatuses,
  };
};
