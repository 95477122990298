/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable func-names */
import React, { useState, useRef, useEffect } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import LogRocket from 'logrocket';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { isCreditCard } from 'validator';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { get, isEmpty, filter, toLower, includes } from 'lodash';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Numeral from 'numeral';
import parse from 'html-react-parser';
import SignatureCanvas from 'react-signature-canvas';
import 'moment/locale/es';

import { Card, CardContent, RadioGroup } from '@material-ui/core';
import moment from 'moment';

import Loader from '../../components/Loader';

import config from '../../config';
import arrowUp from '../../assets/arrow-up.svg';
import arrowDown from '../../assets/arrow-down.svg';
import icons from '../../assets/icons.svg';
import threeDots from '../../assets/three-dots.svg';

// import { media } from '../../helper';

import {
  Content,
  ContainerCard,
  NavBarContainer,
  ContainerGeneral,
  ContentButonGroup,
  ContentButon,
  ContentDialog,
  ContainerData,
  ButtonGrupDialog,
  ContainerDialogTerms,
  ContainerCanvas,
} from './styledComponents';
import BeneficiariosForm from '../../components/BeneficiariosForm';

import incode from '../../incode';

const numberRegex = /^\d+$/;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
  // eslint-disable-next-line consistent-return
  replace: domNode => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

const TextMaskCustom = props => {
  const { inputRef, placeholder, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[4-5]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      placeholder={placeholder}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
};

// This only works for Android, you need to handle iOS
const ResetPermissions = async ({ onTryAgain }) => (
  <div className="reset-permissions">
    <h1>Follow the next steps:</h1>
    <ul>
      <li>
        <span className="number">1</span> <p>Tap the 3 dots</p>{' '}
        <img className="three-dots" alt="three dots" src={threeDots} />
        <img
          className="arrow-up"
          src={arrowUp}
          alt="arrow pointing to the three dots"
        />
      </li>
      <li>
        <span className="number">2</span> <p>Tap this icon</p>{' '}
        <img
          src={arrowDown}
          className="arrow-down"
          alt="arrow pointing to icon with i"
        />
        <div>
          <img src={icons} alt="bar icons" />
        </div>
      </li>
      <li>
        <span className="number">3</span>{' '}
        <p>
          Tap in <span className="blue">Site settings</span>
        </p>
      </li>
      <li>
        <span className="number">4</span>{' '}
        <span className="blue">Allow Permission</span>{' '}
        <p style={{ marginLeft: 10 }}>to Camera</p>
      </li>
    </ul>
    <div className="button-container">
      <button type="button" onClick={onTryAgain}>
        Try Again
      </button>
    </div>
  </div>
);

ResetPermissions.propTypes = {
  onTryAgain: PropTypes.func,
};

const CanjeValeIncode = props => {
  const containerRef = useRef();

  const [activeStep, setActiveStep] = React.useState(0);

  const [cancelVaucher, setCancelVaucher] = useState(false);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState('');

  const [termsAgreedOpen, setTermsAgreedOpen] = useState(false);

  const [editCard, setEditCard] = useState(false);

  const [cardTouched, setCardTouched] = useState(false);
  const [card, setCard] = useState('');

  const [notFound, setNotFound] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmCardTouched, setConfirmCardTouched] = useState(false);
  const [confirmCard, setConfirmCard] = useState('');

  const [errorText, setErrorText] = useState('');
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [banksOptions, setBanksOptions] = useState([]);
  const token = get(props, 'match.params.token', '');
  const [alreadyUsed, setAlreadyUsed] = useState('');
  const [lastDigits, setLastDigits] = useState('');
  const [bank, setBank] = useState('');
  const [newBank, setNewBank] = useState('');

  const [clientInfo, setClientInfo] = useState(null);
  const [loadingFull, setLoadingFull] = useState(false);

  const [successText, setSuccessText] = useState('');
  const [valeCanjeado, setValeCanjeado] = useState('');

  const [showIdentityValidator, setShowIndentityValidator] = useState(false);

  const [voucherRedeemed, setVaucherRedeemed] = useState(false);

  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [suburb, setSuburb] = useState('');
  const [suburbsOptions, setSuburbsOptions] = useState([]);
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');

  const [newPostalCode, setNewPostalCode] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newSuburb, setNewSuburb] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newNumber, setNewNumber] = useState('');

  const [startExchange, setStartExchange] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [existAddress, setExistAddress] = useState(false);
  const [existCard, setExistCard] = useState(null);

  const [myBankName, setMyBankName] = useState('');

  const [confirmCancelVaucher, setConfirmCancelVaucher] = useState(false);

  const [parentescoOptions, setParentescoOptions] = useState([]);
  const [beneficiarios, setBeneficiarios] = useState([
    {
      id: null,
      nombre: '',
      fechaNacimiento: new Date(),
      parentescoId: '',
      porcentaje: 100,
    },
  ]);
  const [beneficiariosCopy, setBeneficiariosCopy] = useState([]);
  const [editBeneficiarios, setEditBeneficiarios] = useState(false);
  const [validBeneficiarios, setValidBeneficiarios] = useState(false);
  const signatureRef = useRef(null);
  const [signature, setSignature] = useState(null);
  const [seguroOptions, setSeguroOptions] = useState([]);
  const [seguroSelected, setSeguroSelected] = useState(8);
  const [cambiaFirma, setCambiaFirma] = useState(false);

  const [session, setSession] = useState();
  const [resetPermissions, setResetPermissions] = useState(false);
  const [inError, setInError] = useState(false);
  const [faceMatch, setFaceMatch] = useState(false);
  const [liveness, setLiveness] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const [urlPerfil, setUrlPerfil] = useState(null);

  // const isMobile = useMediaQuery(media.small);

  useEffect(() => {
    if (newPostalCode.length === 5) {
      fetchDataAddress();
    }
  }, [newPostalCode]);

  useEffect(() => {
    if (activeStep === 1 && !editAddress) {
      setPostalCode(get(clientInfo, 'cliente.domicilio.codigoPostal', ''));
      setCity(get(clientInfo, 'cliente.domicilio.ciudad', ''));
      setState(get(clientInfo, 'cliente.domicilio.estado', ''));
      setSuburb(get(clientInfo, 'cliente.domicilio.colonia', ''));
      setStreet(get(clientInfo, 'cliente.domicilio.calle', ''));
      setNumber(get(clientInfo, 'cliente.domicilio.numeroExterior', ''));
    }
  }, [
    setPostalCode,
    setCity,
    setState,
    setSuburb,
    setStreet,
    setNumber,
    editAddress,
    activeStep,
  ]);

  const searchFilterFunction = () => {
    const newData = filter(banksOptions, item =>
      includes(toLower(item.id), toLower(bank)),
    );
    const data = get(newData[0], 'nombre');
    setMyBankName(data);
  };

  useEffect(() => {
    searchFilterFunction();
  }, [searchFilterFunction]);

  const handleOpenCancelVaocuher = () => {
    setCancelVaucher(true);
  };

  const handleCloseCancelVaucher = () => {
    setCancelVaucher(false);
  };

  const handleCancelVaucher = async () => {
    try {
      setLoading(true);
      const body = {
        token,
      };
      await Axios.post(`${config.api.url}/vales/cancelar-vale`, body);

      setCancelVaucher(false);
      setConfirmCancelVaucher(true);
    } finally {
      setLoading(false);
    }
  };

  const start = () => {
    setStartExchange(true);
  };

  const fetchDataAddress = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`${config.api.url}/colonias`, {
        params: {
          filter: {
            where: {
              codigoPostal: newPostalCode,
            },
          },
        },
      });
      const items = response?.data || [];
      const [firstItem] = items;
      if (!firstItem) return;
      const { nomMunicipio, nomEstado, nomColonia } = firstItem;
      setNewCity(nomMunicipio);
      setNewState(nomEstado);
      setNewSuburb(nomColonia);
      setSuburbsOptions(items);
    } finally {
      setLoading(false);
    }
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleCanjeVale = async () => {
    try {
      setLoadingFull(true);

      const formData = new FormData();

      formData.set('token', token);
      formData.set('type', 'SPEI');

      formData.set('interviewId', session.interviewId);

      // if (showIdentityValidator) {
      formData.set('id', urlPerfil);
      formData.set('origen', 'incode');
      // }

      if (editCard) {
        formData.set('tarjeta', card.replaceAll(' ', ''));
        formData.set('bancoId', newBank);
      }

      if (!editCard) {
        formData.set('tarjeta', lastDigits.replaceAll(' ', ''));
        formData.set('bancoId', bank);
      }

      if (editAddress) {
        formData.set('calle', newStreet);
        formData.set('ciudad', newCity);
        formData.set('estado', newState);
        formData.set('colonia', newSuburb);
        formData.set('codigoPostal', newPostalCode);
        formData.set('numeroExterior', newNumber);
      }

      if (!editAddress) {
        formData.set('calle', street);
        formData.set('ciudad', city);
        formData.set('estado', state);
        formData.set('colonia', suburb);
        formData.set('codigoPostal', postalCode);
        formData.set('numeroExterior', number);
      }

      if (beneficiarios && validBeneficiarios) {
        formData.set('beneficiarios', JSON.stringify(beneficiarios));
      }

      if (seguroSelected) {
        formData.set('seguroId', seguroSelected);
      }

      const response = await Axios.patch(
        `${config.api.url}/vales/canjearVale`,
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
      );

      const isSuccess = get(response, 'data.success', false);

      if (!isSuccess) {
        setErrorText(response.data.message);
        if (response.data.codeName === 'CURP_ERROR') {
          incode.renderCamera('front', containerRef.current, {
            onSuccess: frontIneSuccess,
            onError: handleError,
            token: session,
            noWait: true,
            numberOfTries: -1,
            showTutorial: true,
            showCustomCameraPermissionScreen: true,
            showDoublePermissionsRequest: true,
          });
        } else {
          incode.renderCamera('selfie', containerRef.current, {
            onSuccess: selfieSuccess,
            onError: handleError,
            token: session,
            numberOfTries: -1,
            showTutorial: true,
            showCustomCameraPermissionScreen: true,
            showDoublePermissionsRequest: true,
          });
        }

        return;
      }

      setSuccessText('Vale canjeado con éxito');
      setValeCanjeado('Vale canjeado. ¡Gracias por tu preferencia!');
      setVaucherRedeemed(true);
    } catch (error) {
      console.log('error');
      console.log(JSON.parse(JSON.stringify(error)));
      setErrorText(error.message);

      incode.renderFaceMatch(containerRef.current, {
        onSuccess: faceMatchSuccess,
        onError: handleError,
        token: session,
        liveness,
        userExists,
      });
    } finally {
      setLoadingFull(false);
    }
  };

  const fetchFirma = async firmaDigital => {
    const base64 = await fetch(firmaDigital)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(res => {
          reader.onloadend = () => {
            res(reader.result);
          };
        });
      });
    const signatureEl = signatureRef.current;
    signatureEl.fromDataURL(base64);
    setSignature(firmaDigital);
    setCambiaFirma(false);
  };

  const handleError = e => {
    if (e.type === 'permissionDenied') {
      setResetPermissions(true);
      return;
    }
    setInError(true);
  };

  const faceMatchSuccess = async () => {
    if (faceMatch) {
      await handleCanjeVale();
    } else {
      incode.renderCamera('selfie', containerRef.current, {
        onSuccess: selfieSuccess,
        onError: handleError,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    }
  };

  useEffect(() => {
    if (session) {
      incode.renderFaceMatch(containerRef.current, {
        onSuccess: faceMatchSuccess,
        onError: handleError,
        token: session,
        liveness,
        userExists,
      });
    }
  }, [faceMatch]);

  const selfieSuccess = async data => {
    console.log('selfie foto');
    console.log(data);
    if (data.faceMatch) {
      // Subir imagen a BD
      try {
        const b64Blob = await b64toBlob(data.imageBase64);
        const selfie = new File([b64Blob], 'selfie.png', {
          type: 'image/png',
        });
        setUrlPerfil(selfie);

        setFaceMatch(data.faceMatch);
        setLiveness(data.liveness);
        setUserExists(data.existingUser);
      } catch (e) {
        setErrorText('No se puedo subir foto de perfil. Intente nuevamente.');

        incode.renderCamera('selfie', containerRef.current, {
          onSuccess: selfieSuccess,
          onError: handleError,
          token: session,
          numberOfTries: -1,
          showTutorial: true,
          showCustomCameraPermissionScreen: true,
          showDoublePermissionsRequest: true,
        });
      }
    }
  };

  const backIneSuccess = async data => {
    console.log('back ine');
    console.log(data);
    // Subir imagen de selfie a BD
    try {
      setLoadingFull(true);
      await incode.processId({ token: session.token });

      const formData = new FormData();
      const b64Blob = await b64toBlob(data.frontIdImage);
      const ineFront = new File([b64Blob], 'reversoIne.png', {
        type: 'image/png',
      });
      const clienteId = get(clientInfo, 'clienteId', '');
      formData.append('files', ineFront);
      formData.append('id', clienteId);
      formData.append('tipo', 'Atras');
      await Axios.patch(
        `${config.api.url}/clientes/subirIdentificacion`,
        formData,
      );

      const ocrData = await incode.ocrData({ token: session.token });
      console.log('ocrData');
      console.log(ocrData);
      if (isEmpty(ocrData.curp)) {
        setErrorText('No se puedo verificar su INE. Intente nuevamente.');

        incode.renderCamera('front', containerRef.current, {
          onSuccess: frontIneSuccess,
          onError: handleError,
          token: session,
          noWait: true,
          numberOfTries: -1,
          showTutorial: true,
          showCustomCameraPermissionScreen: true,
          showDoublePermissionsRequest: true,
        });
        return;
      }

      setLoadingFull(false);

      incode.renderCamera('selfie', containerRef.current, {
        onSuccess: selfieSuccess,
        onError: handleError,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    } catch (e) {
      setErrorText('No se puedo subir reverso de su INE. Intente nuevamente.');

      incode.renderCamera('back', containerRef.current, {
        onSuccess: backIneSuccess,
        onError: handleError,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    }
  };

  const frontIneSuccess = async data => {
    console.log('front ine');
    console.log(data);
    try {
      const formData = new FormData();
      const b64Blob = await b64toBlob(data.frontIdImage);
      const ineFront = new File([b64Blob], 'frenteIne.png', {
        type: 'image/png',
      });
      const clienteId = get(clientInfo, 'clienteId', '');
      formData.append('files', ineFront);
      formData.append('id', clienteId);
      formData.append('tipo', 'Frente');
      await Axios.patch(
        `${config.api.url}/clientes/subirIdentificacion`,
        formData,
      );

      incode.renderCamera('back', containerRef.current, {
        onSuccess: backIneSuccess,
        onError: handleError,
        token: session,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    } catch (e) {
      console.log('ERROR SUBIR INE FRENTE');
      console.log(e);
      setErrorText('No se puedo subir frente de su INE. Intente nuevamente.');

      incode.renderCamera('front', containerRef.current, {
        onSuccess: frontIneSuccess,
        onError: handleError,
        token: session,
        noWait: true,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
      });
    }
  };

  // const redirectMobileSuccess = async data => {
  //   console.log('data');
  //   console.log(data);
  //   if (data.onboardingStatus === 'ONBOARDING_FINISHED') {
  //     await handleCanjeVale();
  //   }
  // };

  useEffect(() => {
    if (activeStep === 5 && session) {
      // if (!isMobile) {
      //   incode.renderRedirectToMobile(containerRef.current, {
      //     onSuccess: redirectMobileSuccess,
      //     session,
      //     showSms: false,
      //     skipDesktopRedirect: true,
      //   });
      // } else {
      incode.renderCamera('front', containerRef.current, {
        onSuccess: frontIneSuccess,
        onError: handleError,
        token: session,
        noWait: true,
        numberOfTries: -1,
        showTutorial: true,
        showCustomCameraPermissionScreen: true,
        showDoublePermissionsRequest: true,
        assistedOnboarding: true,
      });
      // }
    }
  }, [activeStep]);

  useEffect(() => {
    fetchData();
    fetchConfig();

    (async () => {
      await initIncode();
    })();

    async function initIncode() {
      await incode.warmup();
      const thisSession = await incode.createSession('ALL', null, {
        configurationId: '66e1eb5c5e9c4630aa54682a',
      });
      console.log('thisSession');
      console.log(thisSession);
      setSession(thisSession);

      await incode.sendGeolocation({ token: thisSession.token });
    }
  }, []);

  useEffect(() => {
    if (activeStep === 4) {
      if (signature) fetchFirma(signature);
    }
  }, [activeStep]);

  const handleNext = async () => {
    if (activeStep === 4 && cambiaFirma) {
      await guardarFirma();
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    if (activeStep === 3) {
      if (beneficiariosCopy.length !== 0) {
        setBeneficiarios(beneficiariosCopy);
      }
      setEditBeneficiarios(false);
    }
  };

  const handleTermsAgreedOpen = () => {
    setTermsAgreedOpen(true);
  };

  const handleTermsAgreedClose = () => {
    setTermsAgreedOpen(false);
  };

  const errorConfirmCard = confirmCardTouched && confirmCard !== card;

  const editCardStatus = e => {
    setEditCard(e);
    if (editCard) {
      setCardTouched(false);
      setInputsDisabled(false);
      setShowIndentityValidator(true);
      fetchData();
    }
  };

  const editAddressStatus = e => {
    setEditAddress(e);
    if (editAddress) {
      fetchData();
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await Axios.get(`${config.api.url}/configuraciones`);
      setTermsAndConditions(get(response, 'data[0].terminosCondiciones', ''));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      let response = await Axios.get(`${config.api.url}/bancos`);
      const banks = get(response, 'data', []) || [];
      setBanksOptions(banks);

      response = await Axios.get(`${config.api.url}/parentescos`, {
        params: {
          filter: {
            where: {
              estatus: 'activo',
            },
          },
        },
      });
      const parentescos = get(response, 'data', []) || [];
      setParentescoOptions(parentescos);

      response = await Axios.get(`${config.api.url}/seguros`, {
        params: {
          filter: {
            where: {
              estatus: 'activo',
            },
          },
        },
      });
      const seguro = get(response, 'data', []) || [];
      setSeguroOptions(seguro);

      response = await Axios.get(`${config.api.url}/vales/busqueda-token`, {
        params: {
          token,
        },
      });

      if (!get(response, 'data')) {
        setNotFound(true);
      }

      const voucherStatus = get(response, 'data.estatus', '');

      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        LogRocket.identify('b2gejo/prestavale-core', {
          name: get(response, 'data.cliente.nombreCompleto', ''),
          celular: get(response, 'data.cliente.celular', ''),
          id: get(response, 'data.cliente.id', ''),
          valeId: get(response, 'data.id', ''),
          token: get(response, 'data.token', ''),
        });
      }

      const firmaDigital = get(response, 'data.cliente.firmaDigital', '');

      if (!isEmpty(firmaDigital)) {
        setSignature(firmaDigital);
      }
      if (voucherStatus !== 'porcobrar') {
        if (voucherStatus === 'cancelado' || voucherStatus === 'bloqueado') {
          setAlreadyUsed(
            'Este vale ha sido cancelado, por favor contacta a tu mayorista para más información.',
          );
        } else if (voucherStatus === 'activo' || voucherStatus === 'cobrado') {
          setAlreadyUsed(
            'Este vale ya fue canjeado. ¡Gracias por tu preferencia!',
          );
        } else {
          setAlreadyUsed(
            'No se encuentra información del vale. Verifique con su distribuidora.',
          );
        }
        return;
      }

      const videoStatus = get(response, 'data.validacionesVideos.estatus', '');

      if (videoStatus === 'porvalidar') {
        setAlreadyUsed('Este vale está en proceso de validación por video.');
        return;
      }

      const disposicion = get(response, 'data.disposicionVale', false);
      if (!disposicion) {
        setAlreadyUsed(
          'No es posible canjear el vale. Distribuidora sin saldo disponible.',
        );
      }

      const estatus = get(response, 'data.cliente.estatus', '');

      if (estatus === 'preregistro') {
        setShowIndentityValidator(true);
      }

      const digits = get(response, 'data.cliente.tarjeta', '');
      if (digits) {
        setLastDigits(digits);
        setCardTouched(true);
      } else {
        setInputsDisabled(false);
      }

      const bankId = get(response, 'data.cliente.bancoId', '');
      if (bankId) {
        setBank(bankId);
        setNewBank(bankId);
      }

      setClientInfo(get(response, 'data', {}));
      const savedAddress = get(response, 'data.cliente.domicilio', {});
      if (!savedAddress) {
        setEditAddress(true);
      }
      setExistAddress(savedAddress);
      setExistCard(get(response, 'data.cliente.tarjeta', {}) || null);
      if (isEmpty(get(response, 'data.cliente.tarjeta', {}))) {
        editCardStatus(true);
      }
      const clienteId = get(response, 'data.clienteId', '');
      response = await Axios.get(`${config.api.url}/beneficiarios`, {
        params: {
          filter: {
            where: {
              clienteId,
              estatus: 'activo',
            },
          },
        },
      });
      const beneficiaries = get(response, 'data', []) || [];
      if (beneficiaries.length !== 0) {
        setValidBeneficiarios(true);
        setBeneficiarios(beneficiaries);
        setBeneficiariosCopy(beneficiaries);
      } else {
        setEditBeneficiarios(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingFull(false);
    }
  };

  const handleValidateBeneficiarios = isValid => {
    setValidBeneficiarios(isValid);
  };

  const onSignatureEnd = () => {
    const signatureEl = signatureRef.current;
    if (!signatureEl.isEmpty()) {
      setCambiaFirma(true);
      setSignature(signatureEl.toDataURL());
    }
  };

  const clearSignature = () => {
    const signatureEl = signatureRef.current;
    signatureEl.clear();
    setSignature(null);
  };

  const guardarFirma = async () => {
    try {
      setLoadingFull(true);
      const formData = new FormData();
      const base64Response = await fetch(signature);
      const blob = await base64Response.blob();
      const firmaFile = new File([blob], 'firma.png', { type: 'image/png' });
      const clienteId = get(clientInfo, 'clienteId', '');
      formData.append('firma', firmaFile);
      await Axios.patch(
        `${config.api.url}/clientes/subirFirma?clienteId=${clienteId}`,
        formData,
      );
    } catch (e) {
      setErrorText('Error al subir imagen de la firma del cliente');
    } finally {
      setLoadingFull(false);
      setSuccessText('Firma enviada con éxito');
    }
  };

  const handleCancelEditBeneficiarios = () => {
    setEditBeneficiarios(false);
    setBeneficiarios(beneficiariosCopy);
  };

  const errorCard = cardTouched && !isCreditCard(card);

  if (resetPermissions) {
    return <ResetPermissions onTryAgain={() => setResetPermissions(false)} />;
  }

  if (inError) return 'Error!';

  return (
    <ContainerGeneral>
      <NavBarContainer>
        <Link to="/">
          <img
            className="logo"
            src="/images/logo-new.png"
            alt="Logo PrestaVale blanco"
          />
        </Link>
      </NavBarContainer>
      {startExchange === false && (
        <>
          {loading && <Loader />}
          {!notFound && Boolean(alreadyUsed) && (
            <>
              <Content>
                <div>{alreadyUsed}</div>
              </Content>
            </>
          )}
          {notFound && (
            <Content>
              <div>
                Este vale no existe o el link es incorrecto. Contacte a su
                distribuidor.
              </div>
            </Content>
          )}
          {!loading && !alreadyUsed && !notFound && (
            <>
              <Content>
                {confirmCancelVaucher === false && (
                  <>
                    <ContainerData>
                      <h3>Folio: </h3>
                      <div className="numbertwo">{`${clientInfo?.folio ||
                        ''}`}</div>
                    </ContainerData>
                    <ContainerData>
                      <h3>{clientInfo.distribuidora.folio} </h3>
                      <div className="numbertwo">
                        {clientInfo?.distribuidora.prospecto.usuario
                          .nombreCompleto || ''}
                      </div>
                    </ContainerData>
                    <div className="firstLabel">{`¡Hola, ${get(
                      clientInfo,
                      'cliente.nombreCompleto',
                      '',
                    )}!`}</div>
                    <ContainerData>
                      <h3>Iniciaras tu canje de vale por</h3>
                      <div className="number">
                        {Numeral(clientInfo?.monto || 0).format('$ 0,0.00 ')}
                      </div>
                    </ContainerData>
                    <ContainerData>
                      <h3>{`a ${clientInfo?.cantidadPagos ||
                        ''} quincenas de `}</h3>
                      <div className="numbertwo">{`${Numeral(
                        clientInfo?.pagoQuincenal || 0,
                      ).format('$ 0,0.00')} pesos`}</div>
                    </ContainerData>

                    <ContainerData>
                      <FormControlLabel
                        control={<Checkbox name="accept" color="primary" />}
                        className="accept-check"
                        onChange={event => setTermsAgreed(event.target.checked)}
                      />
                      <Button onClick={handleTermsAgreedOpen}>
                        Aceptar términos y condiciones.
                      </Button>
                    </ContainerData>

                    <ContentButonGroup>
                      <ContentButon>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={start}
                          disabled={!termsAgreed}
                        >
                          Comenzar canje
                        </Button>
                      </ContentButon>
                      <ContentButon>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleOpenCancelVaocuher}
                        >
                          No quiero el vale
                        </Button>
                      </ContentButon>
                    </ContentButonGroup>
                  </>
                )}

                {confirmCancelVaucher === true && (
                  <>
                    <div className="greeting">Vale cancelado</div>
                    <h2>{`El vale con el folio: ${get(
                      clientInfo,
                      'folio',
                      '',
                    )} ha sido cancelado exitosamente.`}</h2>
                  </>
                )}

                <Dialog
                  open={cancelVaucher}
                  onClose={handleCloseCancelVaucher}
                  maxWidth="sm"
                >
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      zIndex: 10,
                    }}
                    onClick={handleCloseCancelVaucher}
                  >
                    <CloseIcon />
                  </IconButton>
                  <ContentDialog>
                    <h1>¿Estás seguro de que no quieres el vale?</h1>
                    <h2>Al rechazarlo se cancelará y no podrás canjearlo</h2>
                    <ButtonGrupDialog>
                      <Button
                        color="primary"
                        onClick={handleCloseCancelVaucher}
                        style={{ textTransform: 'none', marginRight: 15 }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCancelVaucher}
                        style={{ textTransform: 'none' }}
                      >
                        Aceptar
                      </Button>
                    </ButtonGrupDialog>
                  </ContentDialog>
                </Dialog>
              </Content>
            </>
          )}
        </>
      )}

      {startExchange && (
        <>
          {activeStep === 0 && editCard === false && existCard !== null ? (
            <>
              <Content>
                {loading && <div>Cargando...</div>}
                {!notFound && Boolean(alreadyUsed) && (
                  <>
                    <div>{alreadyUsed}</div>
                  </>
                )}
                {notFound && (
                  <div>
                    Este vale no existe o el link es incorrecto. Contacte a su
                    distribuidor
                  </div>
                )}

                {Boolean(valeCanjeado) && (
                  <div className="greeting">{valeCanjeado}</div>
                )}
                <h1 className="title">Datos bancarios</h1>
                <ContainerCard>
                  <h2>Número de tarjeta</h2>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => editCardStatus(true)}
                  >
                    Editar tarjeta
                  </Button>
                </ContainerCard>
                <h2>{`**** **** **** ${lastDigits.substring(12)}`}</h2>
                <h2>Banco</h2>
                <h2>{myBankName}</h2>
              </Content>
            </>
          ) : null}
        </>
      )}

      {(startExchange && activeStep === 0 && editCard) ||
      (startExchange && activeStep === 0 && existCard === null) ? (
        <Content>
          <h1>Datos bancarios</h1>
          <ContainerCard>
            <h2>Número de tarjeta</h2>
            {editCard && (
              <Button
                variant="text"
                color="primary"
                onClick={() => editCardStatus(false)}
              >
                Cancelar
              </Button>
            )}
          </ContainerCard>
          <TextField
            id="card"
            className="input"
            error={errorCard}
            helperText={errorCard && 'Ingrese una tarjeta válida'}
            value={card}
            placeholder="Número de tarjeta"
            variant="outlined"
            onChange={e => setCard(e.target.value)}
            onBlur={() => {
              setCardTouched(true);
              setShowIndentityValidator(true);
            }}
            InputProps={{
              inputComponent: TextMaskCustom,
              inputProps: {
                placeholder: 'Ingresa los 16 dígitos de tu tarjeta',
              },
            }}
            autoFocus
            disabled={inputsDisabled}
          />
          <TextField
            id="card"
            className="input"
            error={errorConfirmCard}
            helperText={errorConfirmCard && 'Las tarjetas no coinciden'}
            value={confirmCard}
            placeholder="Confirme número de tarjeta"
            variant="outlined"
            onChange={e => setConfirmCard(e.target.value)}
            onBlur={() => setConfirmCardTouched(true)}
            InputProps={{
              inputComponent: TextMaskCustom,
              inputProps: {
                placeholder: 'Confirme número de tarjeta',
              },
            }}
            disabled={inputsDisabled}
          />
          <h2>Banco</h2>
          <FormControl variant="outlined" className="input">
            <Select
              labelId="bank-label"
              id="bank"
              value={newBank}
              onChange={event => setNewBank(event.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Banco' }}
              style={{ color: bank ? '#212121' : '#a2a2a2' }}
              disabled={inputsDisabled}
            >
              <MenuItem value="" disabled>
                Banco
              </MenuItem>
              {banksOptions.map(c => (
                <MenuItem value={c.id} key={c.id}>
                  {c.nombre}
                </MenuItem>
              ))}
              <FormHelperText>Banco</FormHelperText>
            </Select>
          </FormControl>
        </Content>
      ) : null}
      {activeStep === 1 && (
        <Content>
          {!editAddress ? (
            <div>
              <ContainerCard>
                <h1>Dirección</h1>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => editAddressStatus(true)}
                >
                  Editar dirección
                </Button>
              </ContainerCard>
              <div>
                {`${get(clientInfo, 'cliente.domicilio.calle', '')} `}
                {`#${get(clientInfo, 'cliente.domicilio.numeroExterior', '')} `}
                {`C.P. ${get(
                  clientInfo,
                  'cliente.domicilio.codigoPostal',
                  '',
                )} `}
                {`Col. ${get(clientInfo, 'cliente.domicilio.colonia', '')}. `}
                {`${get(clientInfo, 'cliente.domicilio.ciudad', '')}, `}
                {`${get(clientInfo, 'cliente.domicilio.estado', '')}.`}
              </div>
            </div>
          ) : (
            <div>
              <ContainerCard>
                <div className="greeting">Dirección</div>
                {existAddress && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => editAddressStatus(false)}
                  >
                    Cancelar
                  </Button>
                )}
              </ContainerCard>
              <h2>Codigo postal</h2>
              <TextField
                id="postal-code"
                value={newPostalCode}
                variant="outlined"
                onChange={e => {
                  const { value } = e.target;
                  if (!value || numberRegex.test(value)) {
                    setNewPostalCode(value);
                  }
                }}
                fullWidth
                inputProps={{ maxLength: 5 }}
              />

              <h2>Ciudad</h2>
              <TextField
                id="city"
                value={newCity}
                onChange={e => setNewCity(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <h2>Estado</h2>
              <TextField
                id="state"
                value={newState}
                onChange={e => setNewState(e.target.value)}
                variant="outlined"
                fullWidth
              />

              <h2>Colonia</h2>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="suburb-label"
                  id="suburb"
                  value={newSuburb}
                  onChange={event => setNewSuburb(event.target.value)}
                >
                  {suburbsOptions.map(c => (
                    <MenuItem value={c.nomColonia} key={c.id}>
                      {c.nomColonia}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <h2>Calle</h2>
              <TextField
                id="street"
                value={newStreet}
                variant="outlined"
                onChange={e => setNewStreet(e.target.value)}
                fullWidth
              />

              <h2>Número exterior</h2>
              <TextField
                id="number"
                value={newNumber}
                variant="outlined"
                onChange={e => {
                  const { value } = e.target;
                  if (!value || numberRegex.test(value)) {
                    setNewNumber(value);
                  }
                }}
                fullWidth
                inputProps={{ maxLength: 5 }}
              />
            </div>
          )}
        </Content>
      )}
      {activeStep === 2 && (
        <Content>
          <h1>Seguro de Vida</h1>
          <ContainerCard>
            <div className="greeting">
              Selecciona un paquete de seguro de vida. La cuota del seguro se
              sumará a tu pago quincenal.
            </div>
          </ContainerCard>
          <RadioGroup
            aria-label="options"
            name="options"
            value={seguroSelected}
          >
            {seguroOptions.map(option => (
              <Card
                key={option.id}
                variant="outlined"
                style={{
                  marginBottom: '8px',
                  border: `3px solid ${
                    seguroSelected === option.id ? '#00843d' : '#00000042'
                  }`,
                  cursor: 'pointer',
                }}
                onClick={() => setSeguroSelected(option.id)}
              >
                <CardContent>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ fontWeight: 'bold' }}>{option.nombre}</div>
                    <div style={{ color: '#00843d' }}>
                      $ {option.montoAPagar.toFixed(2)} quincenal
                    </div>
                  </div>
                  <div style={{ color: '#808080' }}>
                    ${Numeral(option.importeAsegurado || 0).format('0,0')}
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {option.beneficios.map(beneficio => (
                        <div>{beneficio}</div>
                      ))}
                    </div>
                    {option.default && (
                      <div
                        style={{
                          marginLeft: 'auto',
                          backgroundColor: '#00843d',
                          color: 'white',
                          fontWeight: 'bold',
                          borderRadius: '4px',
                          padding: '4px',
                          marginBottom: 'auto',
                        }}
                      >
                        ¡Recomendado!
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            ))}
          </RadioGroup>
        </Content>
      )}
      {activeStep === 3 && (
        <Content>
          <h1>Beneficiario(s) de Seguro de Vida</h1>
          <ContainerCard>
            <div className="greeting">
              Estoy de acuerdo que el uso de medios electrónicos será utilizado
              para otorgar el consentimiento y la designación de beneficiarios
            </div>
          </ContainerCard>
          {!editBeneficiarios && beneficiariosCopy.length !== 0 ? (
            <div>
              <ContainerCard style={{ marginTop: '20px' }}>
                <div className="greeting">Beneficiarios</div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setEditBeneficiarios(!editBeneficiarios)}
                >
                  Editar beneficiarios
                </Button>
              </ContainerCard>
              <div>
                {beneficiarios.map(beneficiario => (
                  <div key={beneficiario.id} style={{ marginBottom: '20px' }}>
                    <div>
                      <strong>Nombre:</strong> {beneficiario.nombre}
                    </div>
                    <div>
                      <strong>Parentesco:</strong>{' '}
                      {parentescoOptions
                        .find(
                          parentesco =>
                            parentesco.id === beneficiario.parentescoId,
                        )
                        .nombre.toLowerCase()}
                    </div>
                    <div>
                      <strong>Fecha de nacimiento:</strong>{' '}
                      {moment(beneficiario.fechaNacimiento)
                        .locale('es')
                        .format('DD MMMM YYYY')}
                    </div>
                    <div>
                      <strong>Porcentaje asignado:</strong>{' '}
                      {beneficiario.porcentaje}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <ContainerCard style={{ marginTop: '20px' }}>
                <div className="greeting">Beneficiarios</div>
                {beneficiariosCopy.length !== 0 && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleCancelEditBeneficiarios()}
                  >
                    Cancelar
                  </Button>
                )}
              </ContainerCard>
              <BeneficiariosForm
                parentescos={parentescoOptions}
                beneficiarios={beneficiarios}
                setBeneficiariosArray={setBeneficiarios}
                onValidation={handleValidateBeneficiarios}
              />
            </div>
          )}
        </Content>
      )}

      {activeStep === 4 && (
        <Content>
          <h1>Firma del cliente</h1>
          <ContainerCard>
            <div className="greeting">Firma</div>
          </ContainerCard>
          <ContainerCanvas>
            <SignatureCanvas
              penColor="black"
              ref={signatureRef}
              onEnd={() => onSignatureEnd()}
              canvasProps={{
                width: 400,
                height: 200,
                className: 'sigCanvas',
              }}
            />
          </ContainerCanvas>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="primary" onClick={clearSignature}>
              Reiniciar
            </Button>
          </div>
        </Content>
      )}

      {activeStep === 5 && voucherRedeemed === false ? (
        <Content>
          <div ref={containerRef}></div>
        </Content>
      ) : null}

      {voucherRedeemed === true && (
        <Content>
          <div className="greeting">{`Listo, ${get(
            clientInfo,
            'cliente.nombreCompleto',
            '',
          )}!`}</div>
          <div className="instructions">{valeCanjeado}</div>
        </Content>
      )}

      {startExchange && (
        <>
          {voucherRedeemed === false && (
            <MobileStepper
              variant="dots"
              steps={5}
              activeStep={activeStep}
              nextButton={
                <>
                  {activeStep === 5 ? (
                    <Button style={{ visibility: 'hidden' }} />
                  ) : (
                    <Button
                      size="small"
                      onClick={handleNext}
                      color="primary"
                      disabled={
                        activeStep === 5 ||
                        (editAddress &&
                          activeStep === 1 &&
                          (isEmpty(newPostalCode) ||
                            isEmpty(newCity) ||
                            isEmpty(newState) ||
                            isEmpty(newSuburb) ||
                            isEmpty(newStreet) ||
                            isEmpty(newNumber))) ||
                        (editCard && isEmpty(card)) ||
                        (editCard && isEmpty(confirmCard)) ||
                        (editCard &&
                          activeStep === 0 &&
                          confirmCard !== card &&
                          errorCard) ||
                        (editCard && !newBank) ||
                        (editCard &&
                          activeStep === 0 &&
                          cardTouched &&
                          !isCreditCard(card)) ||
                        (editCard &&
                          activeStep === 0 &&
                          confirmCardTouched &&
                          confirmCard !== card) ||
                        (activeStep === 2 && !seguroSelected) ||
                        (editBeneficiarios &&
                          activeStep === 3 &&
                          !validBeneficiarios) ||
                        (activeStep === 4 && !signature)
                      }
                      style={{ textTransform: 'none' }}
                    >
                      Siguiente
                    </Button>
                  )}
                </>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  color="primary"
                  disabled={activeStep === 0}
                  style={{ textTransform: 'none' }}
                >
                  Atrás
                </Button>
              }
            />
          )}
        </>
      )}

      {loadingFull && <Loader />}
      <Dialog
        open={termsAgreedOpen}
        onClose={handleTermsAgreedClose}
        maxWidth="sm"
      >
        <IconButton
          style={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
          onClick={handleTermsAgreedClose}
        >
          <CloseIcon />
        </IconButton>
        <ContainerDialogTerms>
          <div>{parse(termsAndConditions, options)}</div>
        </ContainerDialogTerms>
      </Dialog>
      <Snackbar
        open={Boolean(successText)}
        autoHideDuration={6000}
        onClose={() => setSuccessText('')}
      >
        <Alert onClose={() => setSuccessText('')} severity="success">
          {successText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(errorText)}
        autoHideDuration={6000}
        onClose={() => setErrorText('')}
      >
        <Alert onClose={() => setErrorText('')} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </ContainerGeneral>
  );
};

export default CanjeValeIncode;
