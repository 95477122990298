import styled from 'styled-components';

export const NavBarContainer = styled.div`
  background: #00843d;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
  padding: 16px;

  img {
    height: 24px;
  }
`;

export const Content = styled.div`
  align-items: center;
  padding: 24px 16px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 21px;
    margin-bottom: 12px;
    font-weight: 600;
    color: #000000;
  }

  h2 {
    font-size: 14px;
    color: #5d5d5d;
    margin-bottom: 12px;
    margin-top: 10px;
  }

  h3 {
    color: #18233d;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .greeting {
    color: #454444;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .firstLabel {
    color: #454444;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
    text-transform: capitalize;
    margin-top: 20px;
  }

  .number {
    color: #008733;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .numbertwo {
    color: #18233d;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
    margin-left: 5px;
  }

  .introduction {
    color: #919090;
    font-size: 14px;
    margin-bottom: 8px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #f3f3f3;
  }

  .instructions {
    color: #676767;
    font-size: 14px;
    margin-bottom: 24px;
    font-weight: 500;
  }

  .input {
    margin-bottom: 24px;
    color: #979797;
  }

  .label-description {
    font-size: 12px;
    color: #5d5d5d;
    margin-bottom: 8px;
  }

  .accept-check span:last-child {
    font-weight: normal;
    color: #676767;
    font-size: 14px;
  }

  button span {
    text-transform: none;
    font-weight: bold;
  }

  .Mui-disabled {
    background-color: #f3f3f3;
  }

  .MuiInputBase-root.Mui-disabled {
    color: inherit;
  }

  .IncodeDefaultComponentContainer {
    height: calc(var(--vh) * 88);
  }

  .IncodeTutorialVideoFront {
    height: 40vh;
  }

  .IncodeTutorialVideoBack {
    height: 40vh;
  }

  .IncodeTutorialVideoselfie {
    height: 40vh;
  }

  .IncodeTutorialHeader {
    margin-top: 70px;
  }

  .IncodeTutorialHeaderContainerselfie {
    margin-top: 50px;
  }
`;

export const ContentButon = styled.div`
  max-width: 432px;
  padding: 10px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const ContentButonGroup = styled.div`
  margin-top: 25px;
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerData = styled.div`
  display: flex;
`;

export const ContainerGeneral = styled.div`
  max-width: 100%;
`;

export const ContainerDialogTerms = styled.div`
  max-width: 100%;
  padding: 64px 16px;

  p {
    text-align: justify;
  }
`;

export const ButtonGrup = styled.div`
  margin-top: 25px;
  position: relative;
`;

export const ButtonGrupDialog = styled.div`
  margin-top: 25px;
  width: 100%;
  text-align: end;
  margin-bottom: 25px;
`;

export const ContentTerms = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
`;

export const ContentDialog = styled.div`
  max-width: 432px;
  padding: 5px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 20px;
    margin-bottom: 12px;
    color: #000000;
    margin-top: 60px;
    text-align: center;
  }

  h2 {
    font-size: 17px;
    margin-bottom: 12px;
    color: #666666;
    text-align: center;
  }
`;

export const InputFileContainer = styled.div`
  border: 1px dashed ${props => (props.error ? 'red' : '#d5d5d5')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  color: #5d5d5d;
  font-size: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: opacity 0.25s;

  span {
    margin-top: 4px;
  }

  svg {
    width: 16px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const VideoSection = styled.div`
  color: #5d5d5d;
  font-size: 12px;
  cursor: pointer;
  transition: opacity 0.25s;

  .title {
    font-weight: bold;
  }

  .text {
    margin-bottom: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const LoadingLabel = styled.div`
  padding: 24px;
  font-size: 18px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #5d5d5d;
  margin-bottom: 8px;
`;

export const ErrorLabel = styled.div`
  font-size: 12px;
  color: red;
`;

export const ContainerCanvas = styled.div`
  border: dashed 1px black;
  margin-bottom: 10px;
`;
